import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Introduction = (props) => (
    <header id="introduction" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <Link to="/"><span className="icon fa-book"></span></Link>
        </div>
        <div className="content">
            <div className="inner">
                <h1>New Haven Speculative Fiction Workshop</h1>
                <h3>About Us:</h3>
                <p>Novels, short stories, podcasts, works for screen or stage--we love it all!</p>
                <p>We value diversity and welcome voices writing for a 21st century audience. <br />
                <em>(So, <em>no</em> to fascists, racists, transphobes, homophobes, sexists, etc.)</em></p>

                <p>We keep things democratic, and we play nice. This is not often an issue.</p>

                <p>We meet in a bunker under a disused department store in a parallel universe. <br />
                (Honest.)</p>

                <p><small><em>(For the various parts that went into making this website, check out <a href="/attributions">this link</a>.)</em></small></p>

                <p><Link to="/">Go back to the homepage</Link></p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/introduction">About</Link></li>
                <li><Link to="/members">Members</Link></li>
                <li><Link to="/rules">Rules</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </header>
)

Introduction.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Introduction




 
